$font-weight-bold: 700;
$selected-color: #00AA11;
$unselected-color: #c4c4c4;
$color-white: #fff;
$color-orange: #F28902;

.coverage-option {
  position: relative;
  max-width: 350px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  transition: all .3s ease-in-out;

  @media (max-width: 1080px) {
    margin-bottom: 50px;
  }
}

.coverage-option:hover {
  transform: scale(1.05);
}

.coverage-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  color: var(--title-color, inherit);
}

.coverage-title-selected {
  @extend .coverage-title;
  color: var(--title-color, inherit);
}

.coverage-option-body {
  border: 1px solid $unselected-color;
  z-index: 2;
  border-radius: 25px;
  padding: 33px 43px 33px;
  background-color: $color-white;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @media (max-width: 1080px) {
    padding: 20px 20px 30px;
  }
}

.hidden {
  opacity: 0;
  position: absolute;
}

.selected {
  opacity: 1;
  text-align: center;
  padding: 5px;
  border: 2px solid $unselected-color;
  background: $color-white;
  transition: all .3s ease-in-out;

  position: absolute;
  border-radius: 100%;
  width: 30px;
  right: 17px;
  height: 30px;
  top: 60px;

  .icon-style {
    font-size: 30px;
    color: var(--icon-color);
    font-weight: 700;
  }
}



.coverage-option-body-selected {
  visibility: visible;
  padding: 30px 40px 30px;
  border-radius: 25px;
  border: 5px solid transparent;
  background-color: $color-white;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  // transition: all .3s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 5px;
    background: linear-gradient(180deg, var(--primary-color), var(--secondary-color), var(--tertiary-color));
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  @media (max-width: 1080px) {
    padding: 20px 20px 30px;
  }
}

.coverage-option-pricing {
  padding: 5px;

  .as-low {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    margin-left: 15px;
  }

  .coverage-option-price {
    font-size: 62px;
    font-weight: 700;
    color: #434343;

    @media (max-width: 800px) {
      font-size: 60px
    }
  }

  .coverage-option-monthly {
    font-size: 18px;
    font-weight: 700;
  }
}

.coverage-options-list {
  .coverage-options-list-title {
    font-weight: 700;
    font-size: 18px;
  }

  .coverage-options-description {
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;

    .coverage-option-icon {
      width: 20%;
      height: 40px;
    }
  }
}

.pet-icon {
  position: absolute;
  max-width: 30%;
  top: -15px;
  left: -35px;
  transition: opacity .3s ease-in-out;


  @media (max-width: 1080px) {
    position: absolute;
    max-width: 25%;
    top: -5px;
    left: -10px;
  }
}