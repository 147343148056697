.unninsured-motorist-svg {
  .st4 {
    fill:#FFFFFF;
  }
  .st3 {
    fill:none;
    stroke-width:11;
    stroke-linecap:round;
    stroke-miterlimit:10;
  }
}