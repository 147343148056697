.display-driver {
    min-height: 80vh;
    margin-bottom: 60px;

    .loading-quotes {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        @media (max-width: 1024px) and (min-width: 800px) {
            top: 160px;
        }

        @media (max-width: 480px) {
            top: 154px;
        }
    }
}

.driver-display-tab {
    border: 1px solid #eeeeee;
    margin-bottom: 35px;
    animation: floatUp 1s ease-out forwards;
    opacity: 0;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    width: 600px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: white;

    @media (max-width: 600px) {
        width: auto;
    }
}

.driver-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
}

.display-driver-section {
    width: 600px;
    margin: 0 auto;

    @media (max-width: 600px) {
        width: 90%;
    }
}

.driver-button {
    width: 250px;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #434343;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
}

.driver-add-button:hover {
    transform: scale(1.05);
}


.driver-image svg {
    font-size: 70px;
    height: 60px;
}

.driver-title-text {
    font-weight: 700;
    font-size: 24px;
}

.driver-title-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    text-align: left;

    @media (max-width: 600px) and (min-width: 471px) {
        margin-left: 25px;

    }

    @media (max-width: 470px) {
        margin-left: 15px;
        width: 75%;
    }
}

.driver-age {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    text-align: left;
}

.error-link {
    color: #434343 !important
}

.driver-text-section {
    width: 75%;
    margin-left: 30px;
    word-wrap: break-word;
    white-space: normal;

    @media (max-width: 600px) and (min-width: 471px) {
        margin-left: 25px;
    }

    @media (max-width: 470px) {
        margin-left: 15px;
    }
}


.driver-title {
    color: #434343;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
}

.driver-add-button {
    transition: transform .3s ease-in-out;
    height: 150px;
    width: 600px;
    margin-bottom: 35px;
    border-radius: 30px;
    border: 5px dashed #a79e9e;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    color: #434343;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #EDEDED;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);


    @media (max-width: 600px) {
        width: auto;
    }

    .icon-container {
        display: flex;
        align-items: center;

        .icon {
            font-size: 70px;
            color: white;
            margin-right: 20px;

            @media (max-width: 400px) {
                font-size: 50px;
            }
        }
    }

}

@media only screen and (max-width: 600px) {
    .driver-image svg {
        height: 50px;
    }

    .driver-title-text {
        font-size: 18px;
    }
}

.buttons-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 430px) {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }
}