.payment-content {
    font-family: 'Quicksand', sans-serif;
    margin-top: 35px;

    &-info-card {
        display: flex;
    }

    &-info-card-expiration-date {
        width: 45%;
        margin-right: 10%;
    }

    &-info-card-expiration-cvv {
        width: 45%;
    }
}