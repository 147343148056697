.radio-input label {
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
    font-size: 18px;
}

.radio-input {
    margin-top: 30px;
    width: 100%;

    display: flex;

    @media (max-width: 400px) {
        margin-top: 40px;
    }
}

.radio-input svg {
    width: 35px;
    height: 30px;
}

.radio-input-group {
    display: flex !important;
    flex-direction: row !important;
}

.radio-input-group>label>span {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 600;
    color: #434343;
    font-size: 18px;
}

.input-radio-label-in-line {
    margin-top: -20px;
    width: 60%;
    float: left;

    @media (max-width: 400px) {
        width: 100%;
        float: none;
    }
}

.input-radio-label-in-line .info-button {
    margin-right: 20px;

    @media (max-width: 400px) {
        margin-right: 5px;
    }
}

.input-radio-in-line {
    margin-top: -30px;
    z-index: 2;
    height: 50px;
    width: 40%;
    float: right;
    border-radius: 15px;
    border: 2px solid rgb(224, 224, 222);

    @media (max-width: 400px) {
        margin-top: -20px;
        width: 100%;
        float: none;
    }
}

.radio-inline-container {
    text-align: center;
    height: 100%;
    cursor: pointer;
    height: 40px;
}

.radio-inline-div-circle {
    margin-left: -5px;
    width: 18p;
    height: 18px;
    border-radius: 15px;
    top: 4px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
}

.radio-inline-div-circle-content {
    width: 7px;
    height: 7px;
    border-radius: 15px;
    content: '';
    border: 6px solid white;
}

.radio-inline-container label {
    margin-left: 5px;
}

.radio-inline-container-disabled {
    text-align: center;
    height: 100%;
    cursor: pointer;
    background-color: #eeeeee;
    height: 40px;
    font-weight: 900;
}

.radio-inline-container-disabled label {
    color: #9e9e9e !important;
    margin-left: 5px;
    font-weight: 900;
}

.radio-inline-container-disabled .radio {
    margin-left: -5px;
}

.radio-inline-container-yes {
    padding-top: 12px;
    width: 50%;
    float: left;
    border-right: 2px solid rgb(224, 224, 222);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.radio-inline-container-no {
    padding-top: 12px;
    width: 48.5%;
    float: right;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

    @media (max-width: 400px) {
        width: 48%;
    }
}

.radio-input .helper {
    position: absolute;
    width: 100%;
    margin-top: 35px;
}