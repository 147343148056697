.bridgeV4-container {
  font-family: 'Quicksand', sans-serif;
  color: #434343;
  min-height: 92vh;
  display: flex;
  justify-content: center;

  .not-found {
    font-size: 36px;
    margin-top: 100px;
  }
}