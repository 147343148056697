.driver-license-block {
    width: 400px;
    display: flex;
    flex-direction: column;
    animation: floatUp 1s ease-out forwards;
    animation-delay: 0.1s;
    opacity: 0;

    .buttons-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 500px) {
            width: auto;
        }
    }

    @media (max-width: 400px) {
        width: 90%;
    }
}