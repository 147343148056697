@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

.resume {
    display: flex;
    flex-direction: column;
    min-height: 120vh;
    align-items: center;

    &-error-message {
        color: red;
        font-weight: 700;
        font-family: 'Quicksand', sans-serif;
        margin-bottom: 30px;
        font-size: 20px;
    }

    &-input-block {
        width: 400px;
        display: flex;
        animation: floatUp 1s ease-out forwards;
        animation-delay: 0.3s;
        opacity: 0;
        flex-direction: column;

        @media (max-width: 400px) {
            width: 90%;
        }
    }
}

@media (max-width: 500px) {
    main {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }
}