.policy-license-block {
    width: 400px;
    display: flex;
    min-height: 70vh;
    animation: floatUp 1s ease-out forwards;
    opacity: 0;
    flex-direction: column;

    .buttons-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 400px) {
            width: auto;
        }
    }

    @media (max-width: 400px) {
        width: 90%;
    }
}