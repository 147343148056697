.display-item {
    min-height: 80vh;
    background-color: #fafafa;

    .loading-quotes {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        @media (max-width: 1024px) and (min-width: 800px) {
            top: 160px;
        }

        @media (max-width: 480px) {
            top: 154px;
        }
    }
}

.item-display-tab {
    border: 1px solid #fafafa;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .1s;
    opacity: 0;
    z-index: 2;
    margin-bottom: 35px;
    border-radius: 30px;
    width: 600px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: white;

    @media (max-width: 600px) {
        width: auto;
    }
}


.item-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
}

.display-item-section {
    width: 600px;
    margin: 0 auto;

    @media (max-width: 600px) {
        width: 90%;
    }
}

.item-button {
    width: 250px;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #434343;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
}

.item-add-button:hover {
    transform: scale(1.05);
}


.item-image svg {
    font-size: 70px;
    height: 60px;
}

.item-title-text {
    width: 95%;
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    word-wrap: break-word;
    white-space: normal;

    @media (max-width: 600px) and (min-width: 471px) {
        font-size: 18px;
    }

    @media (max-width: 470px) {
        font-size: 18px;
    }
}

.item-title-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;

}

.item-subtitle-text {
    color: #969696;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    text-align: left;
}

.item-text-section {
    margin-left: 30px;
    width: 100%;

    @media (max-width: 600px) and (min-width: 471px) {
        margin-left: 25px;
        width: 60%;
    }

    @media (max-width: 470px) {
        width: 85%;
        margin-left: 15px;
    }
}

.item-title {
    color: #434343;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
}

@media only screen and (max-width: 600px) {
    .item-image svg {
        height: 50px;
    }

    .item-title-section {
        margin-left: 25px;
        width: 85%;
        text-align: left;
    }

}

.buttons-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 430px) {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }
}