.add-vehicle-input {
    align-items: center;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .3s;
    opacity: 0;
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    width: 600px;
    @media (max-width: 600px) {
        width: 90vw;
        flex-direction: column;
        margin-bottom: 0px;
    }
};

.add-vehicle-form {
    background-color: white;
}

.add-vehicle-option {
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 600px) {
        margin-bottom: 20px;
        width: 90vw;
    }
}

.add-vehicle-option-middle {
    margin-left: 40px;
    margin-right: 40px;
}

.add-vehicle-option-label {
    color: #434343;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 6px;
}