.vehicles-leasing-section {
    display: flex;
    flex-direction: column;
    //z-index: 2;
    align-items: center;
    min-height: 83vh;
    min-width: 400px;
    margin: 0 auto !important;
    @media (max-width: 800px) {
        margin-left: 0%;
        min-width: 90%;
    }
}

.vehicles-leasing-section--hiden {
    opacity: 0;
    height: 0px;
    width: 0px;
    margin-bottom: -50px;
    @media (max-width: 800px) {
        margin-left: 0px;
    }
}
.vehicles-leasing-title {
    animation: floatUp 1s ease-out forwards;
    animation-delay: .1s;
    opacity: 0;
    min-width: 400px;
    font-size: 32px;
    margin-top: 100px;
    margin-bottom: 25px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    color: #434343;

    @media (max-width: 800px) {
        min-width: 90%;
        align-items: center;
        text-align: center;
        font-size: 24px;
        margin: 50px 10px 50px;
    }
    @media (max-width: 500px) {
        min-width: 90%;
        align-items: center;
        text-align: center;
        font-size: 24px;
        margin: 80px 0px 80px;
    }
}

.leasing-form-block {
    animation: floatUp 1s ease-out forwards;
    animation-delay: .1s;
    opacity: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    @media (max-width: 400px) {
        width: 90%;
    }

    .loading-vininfo {
        display: flex;
        justify-content: center;
    }

}

.leasing-inputs {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    height: auto;
    @media (max-width: 800px) {
        margin-top: 20px;
    }
    @media (max-width: 400px) {
        margin-top: 20px;
    }
}
.leasing-input {
    display: block;
    height: 220px;
    transition: all 0.3s ease-out;
}
.leasing-input--hidden {
    opacity: 0;
    height: 0px;
    transition: all 0.3s ease-out;
}
.leasing-inputs .dropdown-input {
    margin-right: 10px;
}

.leasing-inputs .dropdown-input:nth-of-type(2) {
    margin-left: 10px;
    margin-right: 0px;
}

.vehicles-leasing-section .radio-input {
    margin-bottom: 30px;
    @media (max-width: 500px) {
        margin-bottom: 20px;
        width: 100%;
        margin-top: 10px;
        display: block;
    }
}

.vehicles-leasing-section .input-radio-in-line {
    @media (max-width: 500px) {
        display: block;
        width: 100%;
        float: none;
    }
}
.vehicles-leasing-section .input-radio-label-in-line {
    height: 100%;
    @media (max-width: 500px) {
        width: 100%;
        float: none;
        margin-bottom: 40px;
    }
}

.vehicles-leasing-section .radio-inline-container-yes {
    height: 100%;
}
.vehicles-leasing-section .radio-inline-container-no {
    height: 100%;
    @media (max-width: 500px) {
        width: 49%;
    }
}

.vehicles-leasing-section .container-buttons-router {
    z-index: 1000;
}

.vehicles-leasing-section .radio-input .helper {
    position: absolute;
    width: 100%;
    height: 50px;
}
.vehicles-leasing-section .info-modal {
    margin-top: 50px;
}
.vehicles-leasing-section .radio-inline-div-circle {
    width: 18px !important;
}
.vehicles-leasing-section .radio-inline-div-circle-content {
    width: 18px !important;
    height: 18px !important;
}
.vehicles-leasing-section .MuiFormLabel-root {
    text-align: left !important;
}

.carousel-root .selected {
    opacity: 1;
    text-align: center;
    padding: 0px !important;
    transition: all 0.3s ease-in-out;
    position: relative !important;
    border: 0px solid #c4c4c4 !important;
    background: #ffffff !important;
    border-radius: 100%;
    width: 0px !important;
    right: 0px !important;
    height: 0px !important;
    top: 0px !important;
    margin-top: 0px !important;
}

.carousel.carousel-slider {
    z-index: 2;
}