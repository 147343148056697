.display-vehicle {
    min-height: 80vh;
    margin-bottom: 60px;

    .loading-quotes {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        @media (max-width: 1024px) and (min-width: 800px) {
            top: 160px;
        }

        @media (max-width: 480px) {
            top: 154px;
        }
    }
}

.vehicle-display-tab {
    border: 1px solid #eeeeee;
    margin-bottom: 35px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    width: 600px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: white;

    @media (max-width: 600px) {
        width: auto;
    }
}

.vehicle-icon {
    color: #a79e9e;
}

.vehicle-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
}

.display-vehicle-section {
    width: 600px;
    margin: 0 auto;

    @media (max-width: 600px) {
        width: 90%;
    }
}

.vehicle-button {
    width: 250px;
    
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #434343;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
}

.vehicle-add-button:hover {
    transform: scale(1.05);
}


.vehicle-title {
    font-weight: 700;
    color: #434343;
    width: 100%;
    text-align: left;
    font-size: 18px;
    border-bottom: 1px solid #eeeeee;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
}


.vehicle-add-button {
    transition: transform .3s ease-in-out;
    
    height: 150px;
    width: 600px;
    margin-bottom: 35px;
    border-radius: 30px;
    border: 5px dashed #a79e9e;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    color: #434343;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    background-color: #EDEDED;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


@media only screen and (max-width: 600px) {
    .vehicle-display-tab {
        width: 300px;
    }
    .vehicle-button {
        width: 150px;
    }
}