.info-button {
    border: 1px solid #eeeeee;
    background-color: white;
    font-family: 'Quicksand', sans-serif;
    border-radius: 300px;
    min-height: 25px;
    font-size: 14px;
    font-weight: bold;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: color, background-color 0.2s ease-in-out;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.info-button:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}

.info-modal {
    background-color: white;
    border-radius: 7px;
    border: 1px solid #eeeeee;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 50px;
    width: 300px;
    height: 250px;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.info-modal-text {
    font-family: 'Quicksand';
    font-weight: 500;
    color: #434343;
    margin-top: 25px;
    text-align: center;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.info-modal-close {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #434343;
    margin-top: 20px;
    height: 35px;
    width: 10px;
    transition: transform 0.3s ease-in-out;
}

.info-modal-close svg {
    height: 25px;
    cursor: pointer;
    width: 25px;
    margin-left: 25px;
    margin-right: 25px;
    transition: transform 0.2s ease-in-out;
}

.info-modal-close svg:hover {
    transform: scale(1.1);
}

.info-modal-title {
    font-family: 'Quicksand';
    font-weight: 700;
    color: #434343;
    text-align: center;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    font-size: 18px;
    margin-bottom: 15px;
}