.bi-st5 {
    fill: #ffffff;
}
.bi-st6 {
    fill: none;
}
.bi-st0 {
    stroke: #b2b1b1;
    stroke-width: 0 !important;
    stroke-miterlimit: 10;
}
