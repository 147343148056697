.coverages-section {
  font-family: 'Quicksand', sans-serif;
  height: fit-content;
  min-height: 40vh;
}

.loading-progress {
  text-align: center;
}

.coverages-title {
  font-size: 36px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 700;
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  color: #434343;

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;
    font-size: 29px;
    margin: 30px 50px 50px;
  }
}


.coverage-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 0px;
  max-width: 1200px;
  min-height: 600px;

  @media (min-width: 801px) and (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
}

.coverage-options-single {
  @extend .coverage-options;
  justify-content: center;
}

.button-row {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  margin-bottom: 45px;
  padding-right: 50px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 560px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-left: 0px;
    padding-right: 0px;
  }
}