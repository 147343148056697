@import '../../styles/variables';

.review-section {
  font-family: 'Quicksand', sans-serif;
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #434343;


  .payplan-dropdown {
    margin-top: 15px;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .3s;
    opacity: 0;

    max-width: 300px;

    @media (max-width: 300px) {
        max-width: 90%;
    }

    .dropdown-input-icon select {
      border-radius: 50px !important;
      font-size: 14px !important;
      height: 16px !important;
      padding-top: 10px !important;
      border: 2px solid rgb(224, 224, 222) !important;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;

    .your-quote-label {
      font-size: 22px;
      animation: floatUp 1s ease-out forwards;
      animation-delay: .1s;
      opacity: 0;
      font-weight: 600;
    }

    .price {
      font-size: 70px;
      animation: floatUp 1s ease-out forwards;
      animation-delay: .1s;
      opacity: 0;
      font-weight: 700;
    }
  }

  .review-quote {
    display: flex;
    padding-top: 30px;
    flex-wrap: wrap;
    width: 75%;
    margin: auto;

    @media (max-width: 1100px) {
      padding: 0px;
      width: 90%;
      justify-content: center;
    }
  
    .user-form {
      flex: 1;
      animation: floatUp 1s ease-out forwards;
      animation-delay: .3s;
      opacity: 0;
      margin-left: 70px;
      align-self: center;

      @media (max-width: 1100px) {
        margin-left: 20px;
        margin-top: 50px;
      }

      @media (max-width: 560px) {
        margin-left: 0;
      }
    }

    .buttons-row {
      width: 100%;
      display: flex;

      @media (max-width: 320px) {
        flex-wrap: wrap-reverse;
        justify-content: center;
      }
    }
  }
}

