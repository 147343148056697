.policy-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 84vh;
}

.input-block {
    width: 400px;
    display: flex;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .3s;
    opacity: 0;
    flex-direction: column;
    @media (max-width: 400px) {
        width: 90%;
    }
}

.quote-headline-two {
    margin-top: 50px;
    margin-bottom: 80px;
    font-size: 24px;
    color: #434343;
    text-align: center;
    animation: floatUp 1.5s ease-out forwards;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
}
