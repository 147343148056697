.main-progress-bar {
    padding: 0px 20px 10px 20px;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .custom-bar-style {
        >div>div>div {
            background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
        }
    }
}

@media (max-width: 800px) {
    .main-progress-bar {
        padding: 0px 10px 10px 10px;
    }
}

.bar-title-step {
    font-size: 18px;
}

@media (max-width: 600px) {
    .bar-title-step {
        font-size: 15px;
        margin-top: 20px;
    }
}

.bar-steps {
    display: flex;
}

.bar-current-icon {
    cursor: pointer;
    padding-top: 14px;
    width: 3%;
    min-width: 35px;
    margin-right: 5px;
}

.disabled-menu {
    @extend .bar-current-icon;
    opacity: 0.3;
    cursor: default;
}

.bar-current-step {
    width: 47%;
}

.bar-next-step {
    width: 50%;
    text-align: right;
}

@media (max-width: 800px) {
    .bar-current-icon {
        padding-top: 15px;
        width: 10%;
    }

    .bar-current-step {
        width: 45%;
    }

    .bar-next-step {
        width: 45%;
        text-align: right;
    }
}

.bar-next-step--hidden {
    display: none;
}

.progress-bar {
    width: 100%;
}

.progress-burger {
    background-color: #fafafa;
    left: 0;
    width: 500px !important;
}

@media (max-width: 1000px) {
    .progress-burger {
        width: 500px !important;
    }
}

@media (max-width: 800px) {
    .progress-burger {
        width: 500px !important;
    }
}

@media (max-width: 500px) {
    .progress-burger {
        width: 100% !important;
    }
}

.bm-overlay {
    width: 100% !important;
    height: calc(100vh) !important;
    z-index: 1001 !important;
}

@media (max-width: 1000px) {
    .bm-overlay {
        width: 100% !important;
        height: calc(100vh) !important;
    }
}

@media (max-width: 800px) {
    .bm-overlay {
        width: 100% !important;
        height: calc(100vh) !important;
    }
}

.not-overflow {
    overflow: hidden;
}