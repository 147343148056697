.purchase-main {
    font-family: 'Quicksand', sans-serif;
    min-height: 100vh;
    .MuiFormLabel-root {
        text-align: left !important;
    }
    &-header {
        padding-top: 30px;
        background-color: #eeeeee;
        margin: 0 auto !important;
        text-align: center;
        color: #434343;
        h2 {
            animation: floatUp 1s ease-out forwards;
            animation-delay: .1s;
            opacity: 0;
        }
    }
    &-header-amount {
        font-size: 70px;
        animation: floatUp 1s ease-out forwards;
        animation-delay: .2s;
        opacity: 0;
    }
    &-content {
        animation: floatUp 1s ease-out forwards;
        animation-delay: .3s;
        opacity: 0;
        padding-top: 30px;
        margin: 0 auto !important;
        text-align: center;
        padding-left: 5rem;
        padding-right: 5rem;
        @media (max-width: 800px) {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }
        max-width: 400px;
        @media (max-width: 400px) {
            max-width: 90%;
        }
        &-address {
            margin-top: 30px;
            display: block;
            transition: all 0.2s ease-in-out;
        }
        &-address--hidden {
            opacity: 0;
            height: 0px;
            transition: all 0.2s ease-in-out;
        }
    }
    &-payments-list {
        margin: 0 auto !important;
        animation: floatUp 1s ease-out forwards;
        animation-delay: .2s;
        opacity: 0;

        max-width: 300px;
        @media (max-width: 300px) {
            max-width: 90%;
        }
    }
    &-payments-list .dropdown-input-icon select {
        border-radius: 50px !important;
        font-size: 14px !important;
        height: 16px !important;
        padding-top: 10px !important;
        border: 2px solid rgb(224, 224, 222) !important;
    }
    .container-buttons-router {
        position: relative;
    }
    .radio-input .helper {
        margin-top: 30px !important;

        @media (max-width: 500px) {
            margin-top: 50px !important;
        }
    }
    .autocomplete-dropdown-container {
        text-align: left;
    }

    .buttons-row {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: 430px) {
            flex-wrap: wrap-reverse;
            justify-content: center;
        }
    }
    .loading-quotes {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        @media (max-width: 1024px) and (min-width: 800px) {
            top: 160px;
        }

        @media (max-width: 480px) {
            top: 154px;
        }
    }
}
