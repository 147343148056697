.address {
    display: flex;
    flex-direction: column;
    min-height: 88vh;
    align-items: center;
}

.address-section {
    width: 400px;
    opacity: 0;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .3s;
    display: flex;
    flex-direction: column;
    @media (max-width: 400px) {
        width: 90%;
    }
}
