.dropdown-input select {
    border: 1px solid #eeeeee;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: white;
    border-radius: 5px;
    padding-left: 15px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 20px;
    height: unset;
}

.dropdown-input .helper {
    height: 35px;
}

.dropdown-input select>option:checked {
    background-color: red;
}

.dropdown-input label {
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
}

.dropdown-input {
    display: flex;
    flex-direction: column;
}

.dropdown-input-icon select {
    border: 1px solid #eeeeee;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: white;
    border-radius: 15px;
    padding-left: 35px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 20px;
    height: unset;
}

.dropdown-input-icon .helper {
    height: 35px;
}

.dropdown-input-icon select>option:checked {
    background-color: red;
}

.dropdown-input-icon label {
    margin-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
}

.dropdown-input-icon {
    display: flex;
    flex-direction: column;
}