.success-section {
  font-family: 'Quicksand', sans-serif;
  color: #434343;
  min-height: 40vh;

  .account-section {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .4s;
    opacity: 0;
  }
  .header-section {
    display: flex;
    margin-top: 75px;
    justify-content: center;
    margin-left: -10%;
    margin-right: -10%;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      padding-top: 50px;
      padding-bottom: 35px;
  
      .icon {
        width: 100px;
        height: fit-content;
        animation: floatUp 1s ease-out forwards;
        animation-delay: .1s;
        opacity: 0;
      }
  
      .header-label {
        font-size: 60px;
        margin-top: 25px;
        font-weight: 800;
        animation: floatUp 1s ease-out forwards;
        animation-delay: .1s;
        opacity: 0;
        
      }
    }
  }

  .success-content {
    max-width: 500px;
    margin: 20px auto;
    font-weight: 500;
    text-align: center;
    padding: 3em 0 3em 0;

    .lorem {
      animation: floatUp 1s ease-out forwards;
      animation-delay: .2s;
      opacity: 0;
    }

    .lorem-text {
      animation: floatUp 1s ease-out forwards;
      animation-delay: .2s;
      opacity: 0;
    }

    .content-label {
      font-size: 24px;
      font-weight: 600;
      color: #434343;
      text-align: center;
      animation: floatUp 1s ease-out forwards;
      animation-delay: .3s;
      opacity: 0;

    }

    .password-label {
      animation: floatUp 1s ease-out forwards;
      animation-delay: .2s;
      opacity: 0;
    }

    .payment-info {
      font-family: inherit;
      display: flex;
      justify-content: space-between;
      text-align: left;
      flex-wrap: wrap;
      margin-top: 50px;
      animation: floatUp 1s ease-out forwards;
      animation-delay: .3s;
      opacity: 0;

      @media (max-width: 320px) {
        flex-direction: column;
      }

      @media (max-width: 464px) {
        justify-content: center;
        margin-bottom: 75px;
      }

      .payment-info-section {
        margin-bottom: 15px;
        
        .label-title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }

    .account {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 50px;
      @media (max-width: 420px) {
        margin: 50px 0 30px;
      }
    }

    .buttons-row {
      display: flex;
      justify-content: center;
    }
  }
}
