.combined-input-group {
    display: flex;
    flex-direction: column;
}

.combined-input-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.combined-input {
    justify-content: center;
}

.combined-input label {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
}

.combined-input-large label {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    color: #434343;
    font-size: 18px;
}

.combined input {
    border-bottom: 2px solid rgb(224, 224, 222);
    border-top: 2px solid rgb(224, 224, 222);
    border-left: 2px solid rgb(224, 224, 222);
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding-left: 20px;
    margin-top: 10px;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-family: 'Quicksand', sans-serif;
    background-color: white;
    color: #434343;
    font-weight: 600;
}

.input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
}

.input-label label {
    margin-right: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    color: #434343;
}

.combined-large input {
    border-bottom: 2px solid rgb(224, 224, 222);
    border-top: 2px solid rgb(224, 224, 222);
    border-left: 2px solid rgb(224, 224, 222);
    padding-left: 20px;
    margin-top: 10px;
    font-size: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: 'Quicksand', sans-serif;
    background-color: white;
    color: #434343;
    font-weight: 600;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.combined-input p {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}

.combined-button {
    min-width: 75px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    cursor: pointer;
    height: 54px;
    margin-top: 8px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: background-color 0.2s ease-in-out;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.combined-button-large {
    width: 150px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    cursor: pointer;
    height: 58px;
    margin-top: 10px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: background-color 0.2s ease-in-out;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}