//BUTTONS
.container-buttons-router {
    width: 100%;
    z-index: 1000;
}
.container-buttons-router-left {
    width: 50%;
    float: left;
}

.container-buttons-router-left .button {
    float: left;
    @media (max-width: 450px) {
        width: 99% !important;
        font-size: 15px;
    }
}
.container-buttons-router-right .button {
    float: right;
    @media (max-width: 450px) {
        width: 50% !important;
        font-size: 15px;
    }
}
//INPUTS
.custom-input {
    border-radius: 15px !important;
    border: 2px solid rgb(224, 224, 222) !important;
}
.custom-input-date {
    padding-left: 50px !important;
    border-radius: 15px !important;
    border: 2px solid rgb(224, 224, 222) !important;
}
.dropdown-input select {
    border-radius: 15px !important;
    border: 2px solid rgb(224, 224, 222) !important;
}
::-webkit-calendar-picker-indicator {
    color: rgb(224, 224, 222);
    opacity: 1;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 0.5rem;
    float: left;
    margin-right: 12px;
    position: absolute;
    left: 5px;
}

@-moz-document url-prefix() {
    .custom-input-date {
        padding-left: 16px !important;
        border-radius: 15px !important;
        border: 2px solid rgb(224, 224, 222) !important;
    }
    ::-webkit-calendar-picker-indicator {
        color: rgb(224, 224, 222);
        opacity: 1;
        width: 0px;
        height: 0px;
        cursor: pointer;
    }
}