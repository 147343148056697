.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #eeeeee !important;
    border-radius: 7px;
    height: 200px;
    width: 300px;
    user-select: none;
    animation: flowUp 1.5s ease-out forwards;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modal:focus {
    outline: 0;
}

.modal-headline {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
}

.modal-loader {
    margin-top: 35px;
}


@keyframes flowUp {

    from {
        opacity: 0;
        transform: translateY(50px)
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }

}