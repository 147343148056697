.physical-damage-svg {
  .rbi-st0 {
      fill:none;
      stroke: none;
      stroke-width:11;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }

    .rbi-st5{
      fill:none;
      stroke:#7F1819;
      stroke-width:11;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }

    .rbi-st6 {
      fill: #ffffff;
    }
}