@import '../../styles/variables';

.progress-diagram {
    margin-bottom: 25px;
    display: flex;
}

@media (max-width: 500px) {
    .progress-diagram {
        margin-bottom: 2px;
        display: flex;
    }
}

.progress-diagram-icon {
    cursor: pointer;
    width: 80px;
    height: 80px;
    padding-left: 22% !important;
}

@media (max-width: 600px) {
    .progress-diagram-icon {
        padding-left: 15% !important;
    }
}

@media (max-width: 500px) {
    .progress-diagram-icon {
        padding-left: 13% !important;
        width: 60px;
    }
}

@media (max-width: 330px) {
    .progress-diagram-icon {
        padding-left: 13% !important;
        width: 50px;
    }
}

.progress-diagram-step {
    cursor: pointer;
    min-width: 100px;
    padding-top: 7px;
    margin-left: 15px;
    text-align: left;
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    font-size: 20px;
    color: #434343;
}

@media (max-width: 330px) {
    .progress-diagram-step {
        padding-top: 7px;
        font-size: 18px;
    }
}