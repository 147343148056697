.qis-st4 {
    fill: #ffffff;
}
.qis-st6 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 7;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
.qis-st7 {
    fill: none;
}
