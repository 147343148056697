.policy-name-dropdowns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.policy-name-dropdowns .dropdown-input {
    margin-right: 10px;
}

.policy-name-dropdowns .dropdown-input:nth-of-type(2) {
    margin-left: 10px;
    margin-right: 0px;
}

.policy-input-block {
    animation: floatUp 1.5s ease-out forwards;
    opacity: 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media (max-width: 400px) {
        margin-bottom: 20px;
        width: 90%;
    }
}

.policy-name .policy-input-block {
    border-bottom: 2px solid rgb(224, 224, 222);
}

.policy-input-radio-block {
    animation: floatUp 1.5s ease-out forwards;
    width: 400px;
    display: flex;
    flex-direction: column;

    @media (max-width: 400px) {
        width: 90%;
    }
}