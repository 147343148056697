.body {
    height: 16vw;
    overflow: hidden;
    position: absolute;
    bottom: 0%;
    width: 100%;
    left: 0%;
    z-index: -1;
}

.green {
    opacity: 0.8;
    position: absolute;
    width: 100%;
    z-index: 0;
}

.orange {
    opacity: 0.63;
    position: absolute;
    right: -42vw;
    top: -11.5vw;
    width: 100%;
    z-index: 2;
}

.red {
    opacity: 0.63;
    position: absolute;
    top: 2vw;
    width: 100%;
    z-index: 1;
}