.driver-missing-info {
    font-family: 'Quicksand', sans-serif;
    color: #434343;
    min-height: 92vh;
    padding: 50px 15px 100px;
    max-width: 700px;
    margin: 0 auto;

    .driver-item {
        grid-area: center;
        font: inherit;
        font-weight: 600;

        .driver-name-header {
            font-size: 20px;
            margin: 20px 0;
        }
    }
}