.vin-lookup {
    display: flex;
    flex-direction: column;
    min-height: 99vh;
    margin: 0 auto;
    width: 600px;

    @media (max-width: 600px) {
        width: auto;
    }
}

.validation-container {
    animation: floatUp 1s ease-out forwards;
    animation-delay: .2s;
    opacity: 0;
}

.icon-image {
    margin-top: 20px;
    height: 90px;

    @media (max-width: 600px) {
        height: 15vw;
    }
}

.vehicle-icon {
    margin-right: 25px;

    @media (max-width: 600px) {
        margin-right: 4vw;
    }
}

.option-tab-selected {
    background-color: white;
}

.option-tab-unselected {
    background-color: rgb(231, 231, 231);
}

.choose-option {
    align-items: center;
    animation: floatUp 1s ease-out forwards;
    animation-delay: .3s;
    opacity: 0;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    height: 250px;
    margin-bottom: 50px;
    width: 600px;
    align-self: center;

    @media (max-width: 600px) {
        width: 90vw;
    }
}

.option-tab {
    border-top: 2px solid rgb(224, 224, 222);
    border-bottom: 2px solid rgb(224, 224, 222);
    height: 250px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vehicle-option {
    border: 2px solid rgb(224, 224, 222);
    border-radius: 20px;
}

.vehicle-form-label {
    top: -50px;
}

.vin-option {
    border-right: 2px solid rgb(224, 224, 222);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.vin-label {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 13px;
    color: #434343;
}

.vin-option-triangle {
    background: white;
    border-bottom: 2px solid rgb(224, 224, 222);
    border-left: 2px solid rgb(224, 224, 222);
    content: '';
    display: block;
    height: 15px;
    margin-top: 249px;
    position: absolute;
    width: 15px;
    -moz-transform:rotate(315deg);
    -webkit-transform:rotate(315deg);
}
