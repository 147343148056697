

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #ffffff;
}

#root {
    overflow-x: visible;
}

iframe {
    overflow: hidden;
}

.react-router__link {
    text-decoration: none;
}

input:-webkit-autofill {
    -webkit-transition-delay: 9999999s;
    transition-delay: 9999999s;
}

.date-input {
    border-radius: 5px;
    border-top-style: hidden;
    margin-top: 16px;
    border-right-style: hidden;
    border-left-style: hidden;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 6px 0 7px;
    border: solid 1px darkgrey;
}

.spinner-container {
    display: flex;
    justify-content: center;
}

.form-standard {
    margin-top: 1rem;
    padding: 1rem;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-no-shadow {
    margin-top: 1rem;
    padding: 1rem;
}

.form-quick {
    margin-top: 1rem;
    //padding: 1rem;
    padding: 10px 15px 15px 15px;
    width: 300px;
    border-radius: 10px;
    background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}


.form-quick-transparent {
    margin-top: 1rem;
    //padding: 1rem;
    padding: 10px 15px 15px 15px;
    width: 300px;
    border-radius: 10px;
}


.pull-right-container {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
}

.ha-container {
    display: flex;
    flex-direction: row;
}

.va-container {
    display: flex;
    flex-direction: column;
}

// .fakeBigButton {
//     text-align: center;
//     background: #009688;
//     padding: 1rem;
//     box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
//     border-radius: 4px;
//     margin: 1rem 0
// }

.introjs-tooltipbuttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.introjs-prevbutton {
    order: 1
}

.introjs-nextbutton {
    order: 2
}

.introjs-donebutton {
    order: 3;
    margin-left: 1rem;
    font-size: 1rem;
    color: black;
}

@media screen and (max-width: 479px) {

    .form-quick {
        width: 90vw;
        margin-top: 0px;
    }

    
}

@media screen and (max-width: 350px) {

    .form-standard {
        padding: 0;
    }

    
}
