@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

.start {
    display: flex;
    flex-direction: column;
    min-height: 92vh;
    align-items: center;
    justify-content: center;
}

.start-block {
    animation: floatUp 1.5s ease-out forwards;
    animation-delay: .3s;
    opacity: 0;
}

.quote-headline {
    font-size: 48px;
    margin-top: -45px;
    margin-bottom: 75px;
    font-weight: 700;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
    animation: floatUp 1.5s ease-out forwards;
}

@keyframes floatUp {
    from {
        opacity: 0;
        transform: translateY(35px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 400px) {
    .quote-headline {
        font-size: 32px;
        margin-bottom: 50px;
    }
}