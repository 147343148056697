.basic-input input {
    margin-top: 10px;
    font-size: 18px;
    z-index: 2;
    border: 1px solid #eeeeee;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    color: #434343;
    background-color: white;
    border-radius: 5px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.basic-input {
    display: flex;
    flex-direction: column;
}

.basic-input label {
    color: #434343;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
}

.basic-input .helper {
    height: 45px;
}

.sub-helper {
    margin-left: 5px;
    margin-top: 5px;
    transition: opacity 0.3s ease-in-out;
}

.sub-helper p {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}

.custom-input-image-rigth {
    position: absolute;
    margin-top: 36px;
    z-index: 1000;
    margin-left: 350px;
    width: 40px;

    @media (max-width: 400px) {
        margin-left: calc(100vw - 100px);
    }

    @media (max-width: 350px) {
        margin-left: calc(100vw - 80px);
    }
}

.custom-input-icon-success {
    color: green;
    position: absolute;
    margin-top: 44px;
    z-index: 1000;
    font-size: 25px;
    margin-left: 320px;

    @media (max-width: 400px) {
        margin-left: calc(100vw - 130px);
    }

    @media (max-width: 350px) {
        margin-left: calc(100vw - 105px);
    }
}

.custom-input-icon-error {
    color: red;
    position: absolute;
    margin-top: 44px;
    z-index: 1000;
    font-size: 25px;
    margin-left: 320px;

    @media (max-width: 400px) {
        margin-left: calc(100vw - 130px);
    }

    @media (max-width: 350px) {
        margin-left: calc(100vw - 105px);
    }
}