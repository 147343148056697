@import '../../styles/variables';

.button {
    height: 50px;
    margin-top: 35px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    z-index: 2;
    justify-content: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    align-self: flex-end;
    font-size: 18px;
    transition: transform 0.2s ease-in-out, background-color 0.5s ease-in-out;
    background-color: white;
    color: white;
    border-radius: 300px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.float {
    animation: floatUp 1s ease-out forwards;
    //animation-delay: .2s;
    opacity: 0;
}

.disabled {
    @extend .button;
    background-color: $inactive-color !important;
    touch-action: none;
    pointer-events: none;
}

.button:hover {
    transform: scale(1.05);
}

.button-error-message {
    color: #f44336;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
}