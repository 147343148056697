.error-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff;
    border: 1px solid #eeeeee !important;
    border-radius: 7px;
    height: 350px;
    width: 300px;
    user-select: none;
    animation: flowUp 1.5s ease-out forwards;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.error-modal-status {
    font-size: 12px;
    color: #c4c4c4;
    margin-top: 25px;
}

.error-modal-body {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
    font-weight: 600;
}

.modal:focus {
    outline: 0;
}

.modal-headline {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    color: #434343;
}

.modal-loader {
    margin-top: 35px;
}

@keyframes flowUp {

    from {
        opacity: 0;
        transform: translateY(50px)
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }

}