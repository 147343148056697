.driver-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
}

.driver-input-section {
    transition: all 0.2s ease-in-out;
    overflow-y: hidden;
}

.driver-name .input-radio-label-in-line {
    margin-bottom: 20px;
}