.location-search-input {
    width: 100%;
}

.location-search-input input {
    background-color: white;
    margin-top: 10px;
    z-index: 2;
    width: 100%;
    border-radius: 15px !important;
    border: 2px solid rgb(224, 224, 222) !important;
    padding-left: 10px;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-bottom: 15px;
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.location-search-input input:focus {
    border: 1px solid #434343;
}

.suggestion-item {
    color: #434343;
    width: 400px;
    padding-top: 5px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Quicksand', sans-serif;
    margin-right: 0px !important;
    cursor: pointer;
    background-color: #ffffff;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}

@media (max-width: 500px) {
    .suggestion-item {
        width: 90%;
    }
}

.suggestion-name {
    margin-left: 5px;
}

.autocomplete-dropdown-container {
    position: absolute;
    border-radius: 15px;
    z-index: 9999;
}

.address-input label {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #434343;
}

.suggestion-item {
    border-bottom: 1px solid #eeeeee;
}

.suggestion-item:nth-of-type(1) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.suggestion-item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}