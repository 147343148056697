.bm-menu-wrap {
    background-color: white;
    padding: 10px;
    padding-left: 0px;
}

.progress-burger-menu-title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 900;
    font-size: 25px;
    margin-bottom: 50px;
    margin-top: 10px;
    text-align: center;
}

@media (max-width: 400px) {
    .progress-burger-menu-title {
        margin-bottom: 25px;
    }
}

@media (max-width: 330px) {
    .progress-burger-menu-title {
        margin-bottom: 15px;
        margin-top: 5px;
    }
}

.progress-burger-menu-close {
    font-size: 35px;
    text-align: right;
    padding-right: 15px;
    cursor: pointer;
}

/* Color/shape of close button cross */
.bm-cross,
#react-burger-cross-btn {
    background: #000;
}

.bm-item:focus {
    outline: none;
}

.progress-burger-main--hidden {
    display: none !important;
}