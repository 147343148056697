.driver-add-button {
  transition: transform .3s ease-in-out;
  height: 150px;
  width: 600px;
  z-index: 2 !important;
  margin-bottom: 35px;
  border-radius: 30px;
  border: 5px dashed #a79e9e;
  font-weight: 600;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  color: #434343;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #EDEDED;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);


  @media (max-width: 600px) {
    width: auto;
  }

  .icon-container {
    display: flex;
    align-items: center;

    .icon {
      font-size: 70px;
      color: white;
      margin-right: 20px;

      @media (max-width: 400px) {
        font-size: 50px;
      }
    }
  }
}