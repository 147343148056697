.esign-parent {
    display: flex;
    justify-content: center;
    height: 93vh;
    align-items: center;
    flex-direction: column;
}

.esign-iframe {
    width: 90vw;
    animation: floatUp 1s ease-out forwards;
    height: 85vh;
}

.not-loaded {
    visibility: 0;
    opacity: 0;
    display: none;
}
