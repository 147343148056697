@import '../../../styles/variables';

.coverage-info {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  animation: floatUp 1s ease-out forwards;
  animation-delay: .1s;
  opacity: 0;

  @media (max-width: 720px) {
    justify-content: center;
  }

  .mobile-container {
    @media (max-width:375px) {
      width: 100%;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    @media (max-width: 375px) {
      font-size: 14px;
    }

    .tab {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      font-family: inherit;
      font-weight: 700;
      color: white;
      padding: 10px;
      border: 1px solid rgb(204, 204, 204);
      background-color: var(--background-color, #cccccc);
    }

    .tab-selected {
      @extend .tab;
      z-index: 1;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .policy-information {
    width: 300px;
    border: 2px solid #cccccc;
    border-radius: 20px;
    padding: 30px;
    background-color: $light-gray;
    z-index: 1;

    .general-info {
      font-size: 18px;
      margin-bottom: 15px;
      font-weight: 600;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  .policy-information-mobile {
    @extend .policy-information;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;

    @media (max-width: 375px) {
      width: auto;
    }
  }

  .coverage-info-title {
    @extend .row;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;

    .icon {
      padding: 5px;
      font-size: 20px;
      background-color: $white;
      display: flex;
      border-radius: 100%;
      border: 1px solid #cccccc;
      cursor: pointer;
    }

  }

  .coverage {
    padding: 2rem 2rem 1rem 3rem;
    border: 2px solid #cccccc;
    margin-left: -20px;
    background-color: #ffffff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 250px;

    .title {
      @extend .coverage-info-title;
      margin-left: 15px;
    }

    .coverages-list {
      display: grid;
      grid-template-columns: 3fr 3fr;
      justify-content: center;

      .coverage-icon-name {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .coverage-mobile {
    @extend .coverage;
    width: 300px;
    padding: 30px;
    margin-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (max-width: 375px) and (min-width:321px) {
      width: 254px;
    }

    @media (max-width: 320px) {
      width: 215px;
    }
  }

  .vehicle-specific-coverages {
    font-weight: 750;
    padding: 3px;
    font-size: 0.75em;

    ::marker {
      font-size: 0.5em
    }
  }
}