#logo {
    height: 50px;
}

.agency-logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    align-self: center;
}

.nav-menu {
    position: fixed;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-self: center;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #eeeeee;
}

.nav-bar {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.menu-item {
    width: 100%;
    text-align: center;
    padding-top: 25px;
    font-weight: 500;
    padding-bottom: 25px;
    font-family: 'Montserrat', sans-serif;
    color: #222222;
    cursor: pointer;
}



.company-phone {
    align-self: center;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

}

.company-phone a {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    color: #545454;
    cursor: pointer;
}

.menu-item:hover {
    background-image: linear-gradient(to right, #00A3ff, rgba(0, 163, 255, .9));
    color: white;
}

.quote-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 300px;
    width: 150px;
    height: 50px;
    margin-left: 25px;
    cursor: pointer;
    transition: width .6s ease-in-out, opacity .6s ease-out
}

.quote-holder-s {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 300px;
    width: 150px;
    height: 50px;
    margin-left: 25px;
    transition: width .6s ease-in-out, opacity .6s ease-out
}

.quote-block-s {
    transition: .2s ease-out;
}

.quote-block {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    align-self: center;
    transition: .2s ease-out;
}

.move-in {
    transition: .1s ease-in;
    transition-delay: .3s;
}

.entrance-padding {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.dynamic-header {
    margin: auto;
    width: 50%;
    height: 70px;
    position: relative;
}

.centered-logo {
    margin: auto;
}

.header-full {
    display: flex;
    font-size: 1.5rem;
    flex-direction: row;
    color: #eeeeee;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    align-items: center;
    height: 70px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.quote-holder-s {
    display: none;
}

.entrance-header {
    position: fixed;
    height: 70px;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    border-bottom: 1px solid #eeeeee;
}

@media only screen and (max-width: 750px) {
    .centered-logo {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        align-items: center;
    }

    .header-full {
        width: 90%;
    }

    .quote-holder-s {
        display: flex;
        width: 75px;
        z-index: 5;
        cursor: pointer;
    }

    .quote-block-s {
        font-size: 14px;
        font-weight: 600;
    }

    .agency-logo {
        height: 70px;
        position: absolute;
        top: 0%;
        justify-content: center;
        left: 0;
        right: 0;
    }

    .logo-menu {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .company-phone {
        display: none;
    }

}