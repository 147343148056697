@import '../../styles/variables';
@import '../../styles/components';

main {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 5px;
    position: relative;
    background-color: #fafafa;
    z-index: 0;
    overflow: hidden;
}

::-webkit-scrollbar {
    display: none;
}

@media (max-width: 800px) {
    main {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }

    @media (max-width: 870px) {
        main {
            height: initial;
        }
    }

    @media (max-width: 799px) {
        main {
            height: initial;
            padding-bottom: 30px;
        }
    }
}